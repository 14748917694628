import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Header from "../components/Header";
import Slogan from "../components/Slogan";
import Footer from "../components/Footer";

import process from "process";
// import "./Gallery.css";

const Gallery = (props) => {
  console.log(process.env.NODE_ENV);
  const source = process.env.NODE_ENV === "development" ? "dev" : "prod";
  const { galleryInfo } = props;
  return (
    <div className="gallery">
      <Header />
      <Slogan />
      <div className="container">
        <div className="gallery-info">
          <h2 className="gallery-title">{galleryInfo.title}</h2>
          {galleryInfo.description && (
            <p className="gallery-description">{galleryInfo.description}</p>
          )}
        </div>
        <h4 className="gallery-head">
          الصور ({galleryInfo["images"][source].length}):
        </h4>
        <ImageGallery
          showPlayButton={false}
          lazyLoad={true}
          isRTL={true}
          showNav={true}
          showIndex={true}
          disableSwipe={false}
          items={galleryInfo["images"][source]}
        />
        <small className="gallery-note">
          * ملاحظة: لتحميل الصورة بالدقة الكاملة، اضغظ زر ملء الشاشة، ثم قم بحفظ
          الصورة
        </small>

        {galleryInfo.videos.length > 0 && (
          <>
            <h4 className="gallery-head">
              الفيديوهات ({galleryInfo["video"][source].length}):
            </h4>
            {galleryInfo.videos.map((video) => (
              <div className="video" key={video}>
                <iframe
                  width="100%"
                  height="100%"
                  src={video}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            ))}
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Gallery;

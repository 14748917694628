import { FaWhatsapp } from "react-icons/fa";
import { CiPhone } from "react-icons/ci";
import { TfiEmail } from "react-icons/tfi";
import { FaCopyright } from "react-icons/fa";
// import "./Footer.css";

const Footer = (props) => {
  const { sticky } = props || false;
  const classes = sticky ? "footer sticky" : "footer";
  return (
    <footer className={classes}>
      <div className="contact-text">
        <div className="contact-text-element">
          <p>info@provision.pics</p>
          <a rel="noreferrer noopener" href="mailto:info@provision.pics">
            <TfiEmail style={{ color: "white" }} />
          </a>
        </div>
        <div className="contact-text-element">
          <p>0507008535</p>
          <a rel="noreferrer noopener" href="tel:+966507008535">
            <CiPhone style={{ color: "white" }} />
          </a>
          <a
            rel="noreferrer noopener"
            target="_blank"
            href="https://wa.me/+966507008535"
          >
            <FaWhatsapp style={{ color: "white" }} />
          </a>
        </div>
      </div>
      <div className="copyright">
        <span>
          جميع الحقوق محفوظة ProVision <FaCopyright /> 2024
        </span>
      </div>
    </footer>
  );
};

export default Footer;

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import Gallery from "./pages/Gallery";

import "./App.css";

/*********CHANGE LATER****************** */
import Wedding_Mohammad_Abdulghafour from "./galleries_mainfest/Wedding_Mohammad_Abdulghafour.json";
import Wedding_Abdulkaheq_Jajah from "./galleries_mainfest/Wedding_Abdulkhaleq_Jajah.json";
import Wedding_Mohammad_Lababidi from "./galleries_mainfest/Wedding_Mohammad_Lababidi.json";
import Wedding_Mohammad_Nasri from "./galleries_mainfest/Wedding_Mohammad_Nasri.json";
import Personal_Naif_AlSaadi from "./galleries_mainfest/Personal_Naif_AlSaadi.json";
import Personal_Naif_AlJabri from "./galleries_mainfest/Personal_Naif_AlJabri.json";
import Trademark_Roya_Optical from "./galleries_mainfest/Trademarks_Roya_Optical.json";
import Trademark_Coffee_Yuliya from "./galleries_mainfest/Trademarks_Coffee_Yuliya.json";
import Wedding_Yaman_Habib from "./galleries_mainfest/Wedding_Yaman_Habib.json";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/Wedding_Mohammad_Abdulghafour",
    element: <Gallery galleryInfo={Wedding_Mohammad_Abdulghafour} />,
  },
  {
    path: "/Wedding_Abdulkhaleq_Jajah",
    element: <Gallery galleryInfo={Wedding_Abdulkaheq_Jajah} />,
  },
  {
    path: "/Wedding_Mohammad_Lababidi",
    element: <Gallery galleryInfo={Wedding_Mohammad_Lababidi} />,
  },
  {
    path: "/Wedding_Mohammad_Nasri",
    element: <Gallery galleryInfo={Wedding_Mohammad_Nasri} />,
  },
  {
    path: "/Wedding_Yaman_Habib",
    element: <Gallery galleryInfo={Wedding_Yaman_Habib} />,
  },
  {
    path: "/Personal_Naif_AlSaadi",
    element: <Gallery galleryInfo={Personal_Naif_AlSaadi} />,
  },
  {
    path: "/Personal_Naif_AlJabri",
    element: <Gallery galleryInfo={Personal_Naif_AlJabri} />,
  },
  {
    path: "/Trademark_Roya_Optical",
    element: <Gallery galleryInfo={Trademark_Roya_Optical} />,
  },
  {
    path: "/Trademark_Coffee_Yuliya",
    element: <Gallery galleryInfo={Trademark_Coffee_Yuliya} />,
  },
]);

const App = () => <RouterProvider router={router} />;

export default App;

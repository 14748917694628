import Contact from "../components/Contact";
import ProductsCarousel from "../components/ProductsCarousel";
import OurWorksCarousel from "../components/OurWorksCarousel";
import Header from "../components/Header";
import Slogan from "../components/Slogan";
import Footer from "../components/Footer";

const Home = () => (
  <div className="home">
    <Header />
    <Slogan />
    <main>
      <ProductsCarousel />
      <Contact />
      <OurWorksCarousel />
    </main>
    <Footer />
  </div>
);

export default Home;

import { Link } from "react-router-dom";
import logo from "../img/logo_transparent_small.png";
// import "./Header.css";

const Header = () => {
  return (
    <header className="header">
      <p className="header-title">برو فيجن</p>
      <Link to={"/"}>
        <img className="header-logo" src={logo} alt="logo" />
      </Link>
      <p className="header-title">ProVision</p>
    </header>
  );
};

export default Header;

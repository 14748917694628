import Carousel from "./Carousel";

import perfume_dolce_gabbana_1 from "../img/ourworks/perfume_dolce_gabbana_1.png";
import perfune_gucci_1 from "../img/ourworks/perfune_gucci_1.png";
import perfune_gucci_2 from "../img/ourworks/perfune_gucci_2.png";
import glasses_proochi_2453 from "../img/ourworks/glasses_proochi_2453.png";
import glasses_proochi_2502 from "../img/ourworks/glasses_proochi_2502.png";

const OurWorksCarousel = () => {
  const images = [
    {
      original: perfume_dolce_gabbana_1,
      description: "Dolcie & Gabbana",
    },
    {
      original: perfune_gucci_1,
      description: "GUCCI 1",
    },
    {
      original: perfune_gucci_2,
      description: "GUCCI 2",
    },
    {
      original: glasses_proochi_2453,
      description: "Proochi 1",
    },
    {
      original: glasses_proochi_2502,
      description: "Proochi 1",
    },
  ];

  return <Carousel images={images} carouselTitle="أعمالنا" />;
};

export default OurWorksCarousel;

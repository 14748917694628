// import "./Slogan.css";

const Slogan = () => {
  return (
    <div className="slogan-container">
      <div className="slogan">
        <div className="arabic-slogan">لنوصلك بالعالم</div>
        <div className="english-slogan">To connect you to the world</div>
      </div>
    </div>
  );
};

export default Slogan;

import Carousel from "./Carousel";

import products_imaging from "../img/products/products_imaging.png";
import content_management from "../img/products/content_management.png";
import ads_campaign from "../img/products/ads_campaign.png";
import event_photography from "../img/products/event_photography.png";
import social_media from "../img/products/social_media.png";
import multimedia_editing from "../img/products/multimedia_editing.png";
import web_design from "../img/products/web_design.png";

const ProductsCarousel = () => {
  const images = [
    {
      original: products_imaging,
      description: "تصوير العلامات التجارية",
    },
    {
      original: content_management,
      description: "إدارة المحتوى",
    },
    {
      original: ads_campaign,
      description: "الحملات الإعلانية",
    },
    {
      original: event_photography,
      description: "تصوير الفعاليات",
    },
    {
      original: social_media,
      description: "تسويق وسائل التواصل",
    },
    {
      original: multimedia_editing,
      description: "التعديل الاحترافي للوسائط",
    },
    {
      original: web_design,
      description: "تصميم وتطوير المواقع",
    },
  ];

  return <Carousel images={images} carouselTitle="خدماتنا" />;
};

export default ProductsCarousel;

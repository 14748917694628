import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
// import "./Carousel.css";

const Carousel = (props) => {
  const { carouselTitle, images } = props;

  return (
    <div className="carousel">
      <p className="carousel-title">{carouselTitle}</p>
      <ImageGallery
        showNav={false}
        showThumbnails={false}
        showFullscreenButton={false}
        showPlayButton={false}
        isRTL={true}
        showBullets={true}
        autoPlay={true}
        items={images}
        infinite={true}
        lazyLoad={true}
        slideInterval={5000}
        slideDuration={1500}
        swipingTransitionDuration={1500}
        useTranslate3D={true}
      />
    </div>
  );
};

export default Carousel;

// import "./Contact.css";
import { FaWhatsapp } from "react-icons/fa";
import { CiPhone } from "react-icons/ci";
import { TfiEmail } from "react-icons/tfi";

const Contact = () => {
  return (
    <div className="contact">
      <p className="contact-text">تواصل معنا</p>
      <div className="contact-icons">
        <a rel="noreferrer noopener" href="mailto:info@provision.pics">
          <TfiEmail style={{ color: "white" }} />
        </a>
        <a rel="noreferrer noopener" href="tel:+966507008535">
          <CiPhone style={{ color: "white" }} />
        </a>
        <a
          rel="noreferrer noopener"
          target="_blank"
          href="https://wa.me/+966507008535"
        >
          <FaWhatsapp style={{ color: "white" }} />
        </a>
      </div>
    </div>
  );
};

export default Contact;
